const zh_HK = {
  title: '告警引擎',
  instance: '引擎例項',
  cluster: '引擎叢集',
  datasource: '資料來源',
  modify_datasource: '修改資料來源',
  clock: '上次心跳時間',
  unauthorized: '你沒有許可權檢視',
};

export default zh_HK;
