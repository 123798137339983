const zh_HK = {
  title: '許可權管理',
  roles: '角色列表',
  role_add: '新增角色',
  edit_title: {
    post: '新增角色',
    put: '編輯角色',
  },
};

export default zh_HK;
